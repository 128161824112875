<template>
  <div class="payment-disbursement-transfer-card">
    <bg-card class="flex justify-space-between align-center">
      <div>
        <bg-text
          size="body-1"
          class="bg-u-text-neutral-500"
        >
          Total Transfer {{ periodLabel }}
        </bg-text>

        <bg-text size="heading-3" class="bg-u-mt-xxs">
          {{ mixNumberFormatterToRupiah(total) }}
        </bg-text>
      </div>

      <bg-button
        v-if="showCancel"
        variant="secondary"
        size="lg"
        :disabled="disabledCancel"
        :loading="loadingCancel"
        class="bg-u-ml-xxxxl"
        @click="showCancelConfirmation"
      >
        Cancel Auto Transfer
      </bg-button>
    </bg-card>

    <bg-modal
      v-model="isCancelConfirmationShown"
      title="Yakin batalkan auto transfer?"
      description="Sistem akan membatalkan transfer otomatis pada semua
      properti dan mengirimkan notifikasi kepada pemilik kos."
      button-main-text="Ya, Batalkan"
      button-second-text="Tidak"
      desktop-size="sm"
      :body-scroll="false"
      data-testid="auto-disburse-cancel-confirmation-modal"
      @click-main-action="onConfirmCancel"
      @click-second-action="isCancelConfirmationShown = false"
    />
  </div>
</template>

<script>
import { BgCard, BgText, BgButton, BgModal } from 'bangul-vue';
import formatter from '@admin/mixins/formatter';

export default {
  name: 'PaymentDisbursementTransferCard',
  components: {
    BgCard,
    BgText,
    BgButton,
    BgModal,
  },
  mixins: [formatter],
  props: {
    periodLabel: {
      type: String,
      default: '',
    },
    total: {
      type: Number,
      default: 0,
    },
    showCancel: {
      type: Boolean,
      default: false
    },
    disabledCancel: {
      type: Boolean,
      default: false
    },
    loadingCancel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isCancelConfirmationShown: false,
    }
  },
  methods: {
    showCancelConfirmation() {
      if (this.disabledCancel) {
        return;
      }

      this.isCancelConfirmationShown = true;
    },

    onConfirmCancel() {
      this.isCancelConfirmationShown = false;
      this.$emit('click-cancel');
    }
  }
};
</script>
